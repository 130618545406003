/**
 *    SPDX-License-Identifier: Apache-2.0
 */
/* eslint-disable */

import React, { Component } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Popover,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { HashRouter as Router, NavLink, useHistory, withRouter } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import Drawer from "@material-ui/core/Drawer";
import Websocket from "react-websocket";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import Loader from "react-loader-spinner";
import Select from "../Styled/Select";
//import classNames from "classnames";
import NotificationsPanel from "../Panels/NotificationsPanel";
import LogoLight from "../../static/images/Logo_light.svg";
import LogoDark from "../../static/images/Logo_dark.svg";
import AdminPanel from "../Panels/AdminPanel";
import { chartOperations, chartSelectors } from "../../state/redux/charts";
import { tableOperations, tableSelectors } from "../../state/redux/tables";
import { themeSelectors, themeActions } from "../../state/redux/theme";
import SVG from "react-inlinesvg";
// import iRegister from "../../static/icon/register.svg";
// import iLogout from "../../static/icon/logout.svg";
import iBell from "../../static/icon/bell.svg";
import iMoon from "../../static/icon/moon.svg";
import iSun from "../../static/icon/sun.svg";

import Auth from './../../state/Auth';

import { authOperations } from "../../state/redux/auth";

import Register from "../Register";

// import Enroll from '../Enroll';

import {
  currentChannelType,
  channelsType,
  getBlockListType,
  getBlocksPerHourType,
  getBlocksPerMinType,
  getChaincodeListType,
  getChannelsType,
  getChangeChannelType,
  getDashStatsType,
  getPeerListType,
  getPeerStatusType,
  getTransactionByOrgType,
  getTransactionPerHourType,
  getTransactionPerMinType,
  refreshType,
} from "../types";

const {
  blockPerHour,
  blockPerMin,
  transactionPerHour,
  transactionPerMin,
  transactionByOrg,
  dashStats,
  changeChannel,
  blockActivity,
  peerStatus,
} = chartOperations;

const { blockList, chaincodeList, channels, peerList, transactionList } =
  tableOperations;

const { currentChannelSelector, blockNotificationsSelector } = chartSelectors;
const { channelsSelector } = tableSelectors;

/* istanbul ignore next */
const styles = (theme) => {
  const { type, modeMobile } = theme.palette;
  const dark = type === "dark";

  return {
    logo: {
      height: 46,
      [modeMobile]: {
        height: 40,
      },
    },
    navbarHeader: {
      margin: "auto",
      maxWidth: theme.bg.size,
      padding: "0.5rem 0.2rem",
      [modeMobile]: {
        padding: "0.5rem",
      },
    },
    header: {
      top: 0,
      zIndex: 100,
      backgroundColor: theme.bg.content,
      boxShadow: theme.shadow.header,
    },
    tab: {
      color: theme.color.text,
      textTransform: "capitalize",
      fontSize: "18px",
      fontWeight: "bold",
      height: 50,
      margin: "10px 16px",
      "&:hover": {
        color: theme.color.active,
      },
      // '@media (max-width: 1415px) and (min-width: 990px)': {
      // 	fontSize: '0.85rem'
      // }
    },
    activeTab: {
      color: theme.color.active,
      height: 60,
      marginTop: 20,
      "&:hover": {
        color: theme.color.active__hover,
      },
    },
    buttonOther: {
      display: "flex",
      marginLeft: 16,
    },
    adminButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingTop: 0,
      marginTop: 0,

      "& + div": {
        marginLeft: 19,
      },
    },
    userInfo: {
      background: theme.bg.item,
      width: "40px",
      height: "40px",
      borderRadius: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    themeSwitch: {
      // height: 50,
      // lineHeight: '50px',
      // textAlign: 'center',
      // marginLeft: 15,
      // width: 100,
      // paddingTop: 0,
      // '@media (max-width: 1415px) and (min-width: 990px)': {
      // 	display: 'flex'
      // },
      // '@media (max-width: 990px)': {
      // 	marginLeft: 0
      // }
    },
    bell: {
      // color: dark ? 'rgb(139, 143, 148)' : '#5f6164',
      // fontSize: '18pt',
      // float: 'none',
      // '&:hover': {
      // 	color: dark ? '#c1d7f0' : '#24272a'
      // }
    },
    channel: {
      width: 200,
      float: "none",
      // '@media (max-width: 1415px) and (min-width: 990px)': {
      // 	width: '9em'
      // }
    },
    channelLoader: {
      textAlign: "center",
      padding: 20,
    },
    loader: {
      margin: "0 auto",
      width: 100,
    },
    sunIcon: {
      // color: dark ? 'rgb(247, 200, 92)' : 'rgb(245, 185, 47)',
      // '@media (max-width: 1415px) and (min-width: 990px)': {
      // 	marginTop: 15
      // }
    },
    moonIcon: {
      // color: dark ? '#9cd7f7' : 'rgb(104, 195, 245)',
      // '@media (max-width: 1415px) and (min-width: 990px)': {
      // 	marginTop: 15
      // }
    },
    logout: {
      marginRight: -3,
    },
    logoutIcon: {
      color: dark ? "rgb(139, 143, 148)" : "#5f6164",
      fontSize: "18pt",
      float: "none",
      "&:hover": {
        color: dark ? "#c1d7f0" : "#24272a",
      },
      marginLeft: 5,
      marginTop: 14,
      cursor: "pointer",
    },
    user: {
      marginRight: -3,
    },
    userIcon: {
      color: dark ? "rgb(139, 143, 148)" : "#5f6164",
      fontSize: "18pt",
      float: "none",
      "&:hover": {
        color: dark ? "#c1d7f0" : "#24272a",
      },
      marginLeft: 5,
      marginTop: 14,
      cursor: "pointer",
    },
    toggleIcon: {
      color: theme.color.text,
      fontSize: "1.75em",
      "&:focus": {
        outline: "none",
      },
    },
  };
};

export class HeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      notifyDrawer: false,
      adminDrawer: false,
      channels: [],
      notifyCount: 0,
      notifications: [],
      isLoading: true,
      modalOpen: false,
      registerOpen: false,
      selectedChannel: {},
	    checked: false,
    };
  }

  componentDidMount() {
    const { channels, currentChannel } = this.props;

    const arr = [];
    let selectedValue = {};
    channels &&
      channels.forEach((element) => {
        if (element.channel_genesis_hash === currentChannel) {
          selectedValue = {
            value: element.channel_genesis_hash,
            label: element.channelname,
          };
        }
        arr.push({
          value: element.channel_genesis_hash,
          label: element.channelname,
        });
      });

    this.setState({
      currentChannel: currentChannel,
      channels: arr,
      isLoading: false,
      selectedChannel: selectedValue,
    });

    this.setState({ checked: localStorage.getItem('modeType')==='true' },() => {      
    });
    this.interVal = setInterval(() => {
      this.syncData(currentChannel);
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interVal);
  }

  componentWillReceiveProps(nextProps) {
    const { currentChannel, getChangeChannel } = this.props;
    const options = [];
    let selectedValue = {};
    if (nextProps?.channels?.length > 0) {
      nextProps.channels.forEach((element) => {
        options.push({
          value: element.channel_genesis_hash,
          label: element.channelname,
        });
        if (
          nextProps.currentChannel == null ||
          nextProps.currentChannel === undefined
        ) {
          if (element.channel_genesis_hash != null) {
            selectedValue = {
              value: element.channel_genesis_hash,
              label: element.channelname,
            };
          }
        } else if (element.channel_genesis_hash === nextProps.currentChannel) {
          selectedValue = {
            value: element.channel_genesis_hash,
            label: element.channelname,
          };
        }
      });
    }

    if (
      nextProps.currentChannel === null ||
      nextProps.currentChannel === undefined
    ) {
      getChangeChannel(selectedValue.value);
    }

    this.setState({
      currentChannel: currentChannel,
      channels: options,
      isLoading: false,
      selectedChannel: selectedValue,
    });
    if (nextProps.currentChannel !== currentChannel) {
      this.syncData(nextProps.currentChannel);
    }
  }

  toggle = () => {
    const { isOpen } = this.state;
    if (window.matchMedia("(max-width:1200px)").matches) {
      this.setState({
        isOpen: !isOpen,
      });
    }
  };

  closeToggle = () => this.state.isOpen && this.toggle();

  handleChange = async (selectedChannel) => {
    if (this.state.channels.length > 1) {
      const { getChangeChannel } = this.props;
      clearInterval(this.interVal);
      await this.handleOpen();
      this.setState({ selectedChannel });
      getChangeChannel(selectedChannel.value);
      await this.syncData(selectedChannel.value);
      this.interVal = setInterval(() => {
        this.syncData(selectedChannel.value);
      }, 60000);
    }
    //  this.handleClose();
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  registerOpen = () => {
    this.setState(() => ({ registerOpen: true }));
  };

  registerClose = () => {
    this.setState(() => ({ registerOpen: false }));
  };

  onRegister = () => {
    this.registerClose();
  };

  logout = async () => {
    const result = await this.props.logout();
    if (result.status === "Success") {
      window.location = "/";
    }
  };

  /**enrollOpen = () => {
		this.setState(() => ({ enrollOpen: true }));
	};

	enrollClose = () => {
		this.setState(() => ({ enrollOpen: false }));
	};

	onEnroll = user => {
		alert(JSON.stringify(user, null, 2));
		this.enrollClose();
	}; */

  handleDrawOpen = (drawer) => {
    switch (drawer) {
      case "notifyDrawer": {
        this.setState({ notifyDrawer: !this.state.notifyDrawer });
        this.setState({ notifyCount: 0 });
        break;
      }
      case "adminDrawer": {
        this.setState({ adminDrawer: true });
        break;
      }
      default: {
        break;
      }
    }
  };

  handleDrawClose = (drawer) => {
    switch (drawer) {
      case "notifyDrawer": {
        this.setState({ notifyDrawer: false });
        break;
      }
      case "adminDrawer": {
        this.setState({ adminDrawer: false });
        break;
      }
      default: {
        break;
      }
    }
  };

  handleThemeChange = (mode) => {
    const { refresh } = this.props;
    refresh(mode === "dark" ? "light" : "dark");
  };

  handleData(notification) {
    // this.props.getNotification(notification);
    const { notifications, notifyCount, currentChannel } = this.state;
    const notifyArr = notifications;
    notifyArr.unshift(JSON.parse(notification));
    this.setState({ notifications: notifyArr });
    this.setState({ notifyCount: notifyCount + 1 });
    this.syncData(currentChannel);
  }

  async reloadChannels() {
    const { getChannels } = this.props;
    await getChannels();
  }
  
  handleCheckClick = (e) => {
    localStorage.setItem('modeType', !this.state.checked);            
    this.setState({ checked: !this.state.checked },() => {    
      Auth.deauthenticateUser();
      setTimeout( window.location.reload(),1000);
    });
  }

  async syncData(currentChannel) {
    const {
      getBlockList,
      getBlocksPerHour,
      getBlocksPerMin,
      getChaincodeList,
      getChannels,
      getDashStats,
      getPeerList,
      getPeerStatus,
      getTransactionByOrg,
      getTransactionList,
      getTransactionPerHour,
      getTransactionPerMin,
      // getBlockActivity,
      getBlockActivityPage,
    } = this.props;

    await Promise.all([
      getBlockList(currentChannel),
      getBlocksPerHour(currentChannel),
      getBlocksPerMin(currentChannel),
      getChaincodeList(currentChannel),
      getChannels(),
      getDashStats(currentChannel),
      // getBlockActivity(currentChannel),
      getBlockActivityPage({
        channels: currentChannel,
        page: this.props.blockNotifications.page,
      }),
      getPeerList(currentChannel),
      getPeerStatus(currentChannel),
      getTransactionByOrg(currentChannel),
      getTransactionList(currentChannel),
      getTransactionPerHour(currentChannel),
      getTransactionPerMin(currentChannel),
    ]);
    this.handleClose();
  }


  render() {
    const { mode, classes, typeMode } = this.props;
    const { hostname, port } = window.location;
    const webSocketProtocol =
      window.location.protocol === "https:" ? "wss" : "ws";
    const webSocketUrl = `${webSocketProtocol}://${hostname}:${port}/`;
    const dark = mode === "dark";
    const {
      isLoading,
      selectedChannel,
      channels,
      notifyCount,
      notifyDrawer,
      adminDrawer,
      modalOpen,
      registerOpen,
      notifications,
    } = this.state;

    const links = [
      { to: "/", label: "dashboard", exact: true },
      { to: "/network", label: "network" },
      { to: "/blocks", label: "blocks" },
      { to: "/transactions", label: "transactions" },
      { to: "/chaincodes", label: "chaincodes" },
      { to: "/channels", label: "channels" },
      { to: "/holders", label: "Holders" },
    ];

    return (
      <>
        {/* production */}
        {/* development */}
        <Websocket
          url={webSocketUrl}
          onMessage={this.handleData.bind(this)}
          reconnect
        />
        <Router>
          <>
          
            <div
              className={`${classes.header} header w-100 position-fixed`}
              // onMouseLeave={this.closeToggle}
            >
              <Navbar className={classes.navbarHeader} expand="xl">
                {/* Logo */}
                <NavbarBrand href="/">
                  <img
                    src={dark ? LogoDark : LogoLight}
                    className={classes.logo + ` logo`}
                    alt="Integra Blockchain Explorer"
                  />
                </NavbarBrand>
                {/* Menu Mobile: Button */}
                <NavbarToggler onClick={this.toggle}>
                  <FontAwesome name="bars" className={classes.toggleIcon} />
                </NavbarToggler>
                {/* Menu PC */}
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className={`ml-auto navbar-left ${classes.menu}`} navbar>
                    {links.map(({ to, label, ...props }) => (
                      <li key={to}>
                        <NavLink
                          to={to}
                          className={classes.tab + ` tab`}
                          activeClassName={classes.activeTab}
                          onClick={this.toggle}
                          {...props}
                        >
                          {label}
                        </NavLink>
                      </li>
                    ))}
                    {/* Button */}
                    <label className="switch">
                        <input type="checkbox" id="togBtn" value="production" checked={this.state.checked} onChange={this.handleCheckClick}/>
                        <div className="slider round">
                          {/*ADDED HTML */}
                          <span className="on">Mainnet</span>
                          <span className="off">Testnet</span>
                          {/*END*/}
                        </div>
                      </label>


                    <div className={classes.buttonOther + ` buttonOther`}>
                      <div className={classes.adminButton + ` adminButton`}>
                        <Select
                          className={classes.channel + ` select-channel`}
                          placeholder="Select Channel..."
                          required
                          name="form-field-name"
                          isLoading={isLoading}
                          value={selectedChannel}
                          onChange={this.handleChange}
                          onFocus={this.reloadChannels.bind(this)}
                          options={channels}
                        />
                      </div>
                      {/* Notification */}
                      <div
                        id="notification"
                        className={classes.adminButton + ` adminButton`}
                        onClick={() => this.handleDrawOpen("notifyDrawer")}
                      >
                        <Badge badgeContent={notifyCount} color="error">
                          <SVG src={iBell} width={22} height={22} />
                        </Badge>
                      </div>
                     
                      {/*
              //Use when Admin functionality is required
              <div className={classes.adminButton}>
                <FontAwesome
                  name='cog'
                  className='cog'
                  onClick={() => this.handleDrawOpen('adminDrawer')}
                />
              </div> */}
                      {/* Dark mode */}
                      <div className={classes.adminButton + ` adminButton`}>
                        <div onClick={() => this.handleThemeChange(mode)}>
                          {dark ? (
                            <SVG src={iSun} className={classes.sunIcon} />
                          ) : (
                            <SVG src={iMoon} className={classes.moonIcon} />
                          )}
                        </div>
                      </div>
                      {/* Register */}
                      {/* <div className={classes.adminButton + ` adminButton`}>
												<div className={classes.userInfo} onClick={() => this.registerOpen()}>
													<SVG src={iRegister} />
												</div>
											</div> */}
                      {/* @@@@@@@@@@@@@ Logout @@@@@@@@@@@@@@@ */}
                      {/* <div className={classNames(classes.adminButton, "adminButton")}>
												<div onClick={() => this.logout()} >
													<SVG src={iLogout} />
												</div>
											</div> */}
                    </div>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
            {/* Notification */}
            <UncontrolledPopover
              className="notification"
              trigger="legacy"
              placement="bottom"
              // isOpen={notifyDrawer}
              target="notification"
              // onClose={() => this.handleDrawClose('notifyDrawer')}
            >
              <PopoverHeader>Notification</PopoverHeader>
              <PopoverBody>
                <NotificationsPanel notifications={notifications} />
              </PopoverBody>
            </UncontrolledPopover>
            {/*  */}
            <Drawer
              anchor="right"
              open={adminDrawer}
              onClose={() => this.handleDrawClose("adminDrawer")}
            >
              <div tabIndex={0} role="button">
                <AdminPanel />
              </div>
            </Drawer>
            {/* Register */}
            <Dialog
              open={registerOpen}
              onClose={this.registerClose}
              fullWidth={false}
              maxWidth="md"
            >
              <Register
                onClose={this.registerClose}
                onRegister={this.onRegister}
              />
            </Dialog>
            {/*  */}
            <Dialog
              open={modalOpen}
              onClose={this.handleClose}
              fullWidth={false}
              maxWidth="md"
            >
              <div className={classes.channelLoader}>
                <h4>Loading Channel Details</h4>
                <Loader
                  type="ThreeDots"
                  color="#005069"
                  height={70}
                  width={70}
                  className={classes.loader}
                />
              </div>
            </Dialog>
          </>
        </Router>
      </>
    );
  }
}

HeaderView.propTypes = {
  currentChannel: currentChannelType.isRequired,
  channels: channelsType.isRequired,
  getBlockList: getBlockListType.isRequired,
  getBlocksPerHour: getBlocksPerHourType.isRequired,
  getBlocksPerMin: getBlocksPerMinType.isRequired,
  getChangeChannel: getChangeChannelType.isRequired,
  getChaincodeList: getChaincodeListType.isRequired,
  getChannels: getChannelsType.isRequired,
  getDashStats: getDashStatsType.isRequired,
  getPeerList: getPeerListType.isRequired,
  getPeerStatus: getPeerStatusType.isRequired,
  getTransactionByOrg: getTransactionByOrgType.isRequired,
  getTransactionPerHour: getTransactionPerHourType.isRequired,
  getTransactionPerMin: getTransactionPerMinType.isRequired,
  refresh: refreshType.isRequired,
};

const { modeSelector, typeModeSelector } = themeSelectors;

const { changeModeType } = themeActions;

export default compose(
  withStyles(styles),
  connect(
    (state) => ({
      currentChannel: currentChannelSelector(state),
      channels: channelsSelector(state),
      mode: modeSelector(state),
	    typeMode: typeModeSelector(state),
      blockNotifications: blockNotificationsSelector(state),
    }),
    {
      getBlockList: blockList,
      getBlocksPerHour: blockPerHour,
      getBlocksPerMin: blockPerMin,
      getChaincodeList: chaincodeList,
      getChangeChannel: changeChannel, // not in syncdata
      getChannels: channels,
      getDashStats: dashStats,
      getPeerList: peerList,
      getPeerStatus: peerStatus,
      getBlockActivity: blockActivity,
      getBlockActivityPage: chartOperations.blockNotificationsPage,
      getTransactionByOrg: transactionByOrg,
      getTransactionList: transactionList,
      getTransactionPerHour: transactionPerHour,
      getTransactionPerMin: transactionPerMin,
      logout: authOperations.logout,
	    changeModeType: changeModeType,
    }
  )
)(withRouter(HeaderView));
