/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { themeSelectors } from '../../state/redux/theme';
import { withStyles } from '@material-ui/core/styles';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import { sha256 } from 'js-sha256';
import { transactionByOrgType } from '../types';
import { Col, Row } from 'reactstrap';

/* istanbul ignore next */
const styles = theme => {
	return {};
};

function intConversion(str) {
	let value = 0;
	for (let i = 0; i < str.length; i++) {
		value = str.charCodeAt(i) + ((value << 5) - value);
	}
	return value;
}

function getRGBColor(i) {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();
	return '#' + '00000'.substring(0, 6 - c.length) + c;
}

function convertSha256(str) {
	const shaString = sha256(str);
	return shaString;
}

export function getOrgColor(org) {
	return getRGBColor(intConversion(convertSha256(org)));
}

export class OrgPieChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// Data dumy
			data: [
				{ value: 7, name: 'Org1MSP', fill: '#096AF5' },
				{ value: 3, name: 'integra-awsMSP', fill: '#FFA73F' },
				{ value: 5, name: 'integra-gcpMSP', fill: '#FF6B6B' },
				{ value: 2, name: 'SotatekMSP', fill: '#37C882' },
				{ value: 3, name: 'OrdererMSP', fill: '#3855B3' }
			],
			// Color default for chart
			colorDefault: [
				'#096AF5',
				'#FFA73F',
				'#FF6B6B',
				'#37C882',
				'#3855B3',
				'#46bdc7',
				'#34a753',
				'#ea4333'
			]
		};
	}

	componentDidMount() {
		const { transactionByOrg } = this.props;
		this.orgDataSetup(transactionByOrg);
	}

	componentWillReceiveProps(nextProps) {
		const { transactionByOrg } = this.props;
		if (nextProps.transactionByOrg !== transactionByOrg) {
			this.orgDataSetup(nextProps.transactionByOrg);
		}
	}

	orgDataSetup = orgData => {
		const temp = [];
		orgData.forEach((element, index) => {
			temp.push({
				value: parseInt(element.count, 10),
				name: element.creator_msp_id,
				// Get color default, default not exist else auto
				fill: this.state.colorDefault[index]
					? this.state.colorDefault[index]
					: getOrgColor(element.creator_msp_id)
			});
		});
		this.setState({ data: temp });
	};

	onRedirect = (event) => {
		this.props.onRedirect.push(`transactions?orgType=${event.name}`);
	}

	render() {
		const { data } = this.state;

		return (
			<div className="section-content">
				<div className="pie">
					<div className="pie-chart">
						<ResponsiveContainer>
							<PieChart>
								<Pie
									data={data}
									dataKey="value"
									nameKey="name"
									cx="50%"
									cy="50%"
									// stroke={isDark ? "#242729" : "#ffffff"}
									strokeWidth={2}
									outerRadius="100%"
									innerRadius="50%"
									fill="fill"
									onClick={(event) => this.onRedirect(event)}
								/>
								<Tooltip cursor={true} />
								<Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
								{/* <Tooltip cursor={<CustomizedCursor />} /> */}
							</PieChart>
						</ResponsiveContainer>
					</div>
					<Row className="pie-legend">
						{data.map(({ value, name, fill }, index) => (
							<Col
								xs={6}
								sm={4}
								key={index}
								className="d-flex align-items-center pie-legend-row"
							>
								<span className="pie-legend-row-icon" style={{ color: fill }}>
									●
								</span>
								<span className="pie-legend-row-title" title={name}>
									            {name}
								</span>
							</Col>
						))}
					</Row>
				</div>
			</div>
		);
	}
}

OrgPieChart.propTypes = {
	transactionByOrg: transactionByOrgType.isRequired
};

const { modeSelector } = themeSelectors;

export default compose(
	withStyles(styles),
	connect(state => ({
		mode: modeSelector(state)
	}))
)(OrgPieChart);
